<template>
  <v-app>
    <AppNavbar v-if="this.$store.state.auth.isAuth && !loading"></AppNavbar>
    <v-main v-if="!loading" class="">
      <router-view :class="this.$store.state.auth.isAuth ? 'ma-2' : ''" />
    </v-main>
    <AppLoading class="ma-0 pa-0" v-if="loading"></AppLoading>

  </v-app>
</template>

<script>
  import AppNavbar from './components/Navbar.vue'
  import AppLoading from './components/Loading.vue'
  export default {
    name: 'App',
    components: {
      AppNavbar,
      AppLoading,
    },

    data: () => ({
      //
      loading: true,
    }),
    mounted() {
      if (!this.$route.params.user_id) {
        this.web_view = false;
      }
      this.$vuetify.theme.dark = false
        this.$vuetify.rtl = false
      if (localStorage.getItem('user')) {
        this.$store.state.auth = {
          user: JSON.parse(localStorage.getItem('user')),
          isAuth: true
        }
          this.loading = false
  
      }else{
        this.loading = false
        

      }
    },
  };
</script>
<style>
@import url('./assets/font.css');
  .view-page {
    font-family: 'Noto Sans Arabic', sans-serif;
  }
  .kurdish {
    font-family: 'Noto Sans Arabic';
  }
</style>