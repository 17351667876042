import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import langs from './lang.json'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        langs: langs,
        lang: langs[0],
        auth: {
            isAuth: false,

            user: {},
        },

        admins: [],

        categories: [],

        cities: [],

        comments: [],

        comment_reports: [],

        comment_votes: [],

        items: [],

        item_badges: [],

        item_images: [],

        notifications: [],

        replies: [],

        reports: [],

        reviews: [],

        towns: [],

        users: [],

        user_badges: [],

        user_reports: [],
        badges: [],

    },
    getters: {
        badges: (s) => s.badges,
        langs(s) {
            return s.langs
        },
        lang(s) {
            return s.lang
        },

        admins(s) {
            return s.admins
        },

        categories(s) {
            return s.categories
        },

        cities(s) {
            return s.cities
        },

        comments(s) {
            return s.comments
        },

        comment_reports(s) {
            return s.comment_reports
        },

        comment_votes(s) {
            return s.comment_votes
        },

        items(s) {
            return s.items
        },

        item_badges(s) {
            return s.item_badges
        },

        item_images(s) {
            return s.item_images
        },

        notifications(s) {
            return s.notifications
        },

        replies(s) {
            return s.replies
        },

        reports(s) {
            return s.reports
        },

        reviews(s) {
            return s.reviews
        },

        towns(s) {
            return s.towns
        },

        users(s) {
            return s.users
        },

        user_badges(s) {
            return s.user_badges
        },

        user_reports(s) {
            return s.user_reports
        },

    },
    mutations: {
        logout(){
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.state.auth.isAuth = false
            this.state.auth.user = {}
        },
        initData(s) {
            axios.get('init.php').then(r => {
                    s.admins = r.data.admins;
                    s.categories = r.data.categories;
                    s.cities = r.data.cities;
                    
                    s.reports = r.data.reports;
                    s.towns = r.data.towns;
                    s.badges = r.data.badges;
                    s.user_badges = r.data.user_badges;
                })
                .catch(e => {})
        },

        get_admins(s) {
            axios.get('admins/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.admins = r.data.rows
                    }
                })
        },

        get_categories(s) {
            axios.get('categories/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.categories = r.data.rows
                    }
                })
        },

        get_cities(s) {
            axios.get('cities/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.cities = r.data.rows
                    }
                })
        },

        get_comments(s) {
            axios.get('comments/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.comments = r.data.rows
                    }
                })
        },

        get_comment_reports(s) {
            axios.get('comment_reports/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.comment_reports = r.data.rows
                    }
                })
        },

        get_comment_votes(s) {
            axios.get('comment_votes/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.comment_votes = r.data.rows
                    }
                })
        },

        get_items(s) {
            axios.get('items/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.items = r.data.rows
                    }
                })
        },
        get_badges(s) {
            axios.get('badges/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.badges = r.data.rows
                    }
                })
        },

        get_item_badges(s) {
            axios.get('item_badges/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.item_badges = r.data.rows
                    }
                })
        },

        get_item_images(s) {
            axios.get('item_images/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.item_images = r.data.rows
                    }
                })
        },

        get_notifications(s) {
            axios.get('notifications/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.notifications = r.data.rows
                    }
                })
        },

        get_replies(s) {
            axios.get('replies/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.replies = r.data.rows
                    }
                })
        },

        get_reports(s) {
            axios.get('reports/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.reports = r.data.rows
                    }
                })
        },

        get_reviews(s) {
            axios.get('reviews/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.reviews = r.data.rows
                    }
                })
        },

        get_towns(s) {
            axios.get('towns/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.towns = r.data.rows
                    }
                })
        },

        get_users(s) {
            axios.get('users/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.users = r.data.rows
                    }
                })
        },

        get_user_badges(s) {
            axios.get('user_badges/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.user_badges = r.data.rows
                    }
                })
        },

        get_user_reports(s) {
            axios.get('user_reports/read.php')
                .then(r => {
                    if (r.data.status == 'ok') {
                        s.user_reports = r.data.rows
                    }
                })
        },


    },

})