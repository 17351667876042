import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import device from "vue-device-detector"
Vue.use(device)

import VueScratchable from 'vue-scratchable';

Vue.component('vue-scratchable', VueScratchable);


Vue.config.productionTip = false
import axios from 'axios'
const api = 'https://api.shayen.me'
const url = 'https://shayen.me'
Vue.prototype.$url = url

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// let api = process.env.NODE_ENV === 'production' ? 'http://shayan-api.baxtiar.com/' : 'http://localhost:88/shayan/api/'
 

Vue.prototype.$api = api

axios.defaults.baseURL = api;
axios.defaults.headers.post['Content-Type'] = 'application/json';
let saved_token = localStorage.getItem('token')
if (saved_token) {
  axios.defaults.headers['Authorization'] = saved_token
}
axios.interceptors.response.use(r =>{
  if (process.env.NODE_ENV != 'production') {
    console.log('URL: '+ r.config.url);
    console.log(r);
  }
  return r
//   if (r.data?.status == 'deny') {
//     console.log('Logout from url');
//     return r
//   }else{
//     if ((r.data.status == 'ok' || r.data.status == 'error' || r.data.status == 'validation') && r.data.token) {
//       console.log('new token saved');
//       axios.defaults.headers['Authorization'] = r.data.token;
//       store.state.auth.user.auth_token = r.data.token
//       localStorage.setItem('user' , JSON.stringify(store.state.auth.user))
//       localStorage.setItem('token',r.data?.token)
//       return r
//     } else if(r.data.status == 'ok' && r.data.token == undefined){
//       console.log('old token used');
//       axios.defaults.headers['Authorization'] = saved_token;
//       store.state.auth = JSON.parse(localStorage.getItem('user'))
//       return r
//     }else if((r.data.status == 'done' || r.data.status == 'error' ) && !r.data.token){
//       return r
//     } else{
//       console.log('bad coding');
//       return r
//     }
//   }
  
}, e =>{
  console.error(e);
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
