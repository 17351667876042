import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)
const routes = [{
    path: '/login',
    name: 'Login',
    component: () => import('./../views/Login.vue'),
    meta: {
      auth: false,
      title: 'Login'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('./../views/Home.vue'),
    meta: {
      auth: true,
      title: 'Home'
    }
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('./../views/Download.vue'),
    meta: {
      auth: false,
      title: 'Download'
    }
  },
  {
    path: '/',
    name: 'About',
    component: () => import('./../views/About.vue'),
    meta: {
      auth: false,
      title: 'About'
    }
  },
  {
    path: '/web-view/:user_id',
    name: 'WebView',
    component: () => import('./../views/WebView.vue'),
    meta: {
      auth: false,
      title: 'WebView'
    }
  },
  {
    path: '/r/:id',
    name: 'Result',
    component: () => import('./../views/Item.vue'),
    meta: {
      auth: false,
      title: 'Result'
    }
  },

  {
    path: '/admins-list',
    name: 'Listadmins',
    component: () => import('./../views/admins/List.vue'),
    meta: {
      auth: true,
      title: 'admins List'
    }
  },
  {
    path: '/admins-list/:id',
    name: 'Viewadmins',
    component: () => import('./../views/admins/View.vue'),
    meta: {
      auth: true,
      title: 'admins List'
    }
  },
  {
    path: '/categories-list',
    name: 'Listcategories',
    component: () => import('./../views/categories/List.vue'),
    meta: {
      auth: true,
      title: 'categories List'
    }
  },
  {
    path: '/categories-list/:id',
    name: 'Viewcategories',
    component: () => import('./../views/categories/View.vue'),
    meta: {
      auth: true,
      title: 'categories List'
    }
  },
  {
    path: '/cities-list',
    name: 'Listcities',
    component: () => import('./../views/cities/List.vue'),
    meta: {
      auth: true,
      title: 'cities List'
    }
  },
  {
    path: '/cities-list/:id',
    name: 'Viewcities',
    component: () => import('./../views/cities/View.vue'),
    meta: {
      auth: true,
      title: 'cities List'
    }
  },
  {
    path: '/badges-list',
    name: 'Listbadges',
    component: () => import('./../views/badges/List.vue'),
    meta: {
      auth: true,
      title: 'badges List'
    }
  },
  {
    path: '/badges-list/:id',
    name: 'Viewbadges',
    component: () => import('./../views/badges/View.vue'),
    meta: {
      auth: true,
      title: 'badges List'
    }
  },
  {
    path: '/comments-list',
    name: 'Listcomments',
    component: () => import('./../views/comments/List.vue'),
    meta: {
      auth: true,
      title: 'comments List'
    }
  },
  {
    path: '/comments-list/:id',
    name: 'Viewcomments',
    component: () => import('./../views/comments/View.vue'),
    meta: {
      auth: true,
      title: 'comments List'
    }
  },
  {
    path: '/comment_reports-list',
    name: 'Listcomment_reports',
    component: () => import('./../views/comment_reports/List.vue'),
    meta: {
      auth: true,
      title: 'comment_reports List'
    }
  },
  {
    path: '/comment_reports-list/:id',
    name: 'Viewcomment_reports',
    component: () => import('./../views/comment_reports/View.vue'),
    meta: {
      auth: true,
      title: 'comment_reports List'
    }
  },
  {
    path: '/comment_votes-list',
    name: 'Listcomment_votes',
    component: () => import('./../views/comment_votes/List.vue'),
    meta: {
      auth: true,
      title: 'comment_votes List'
    }
  },
  {
    path: '/comment_votes-list/:id',
    name: 'Viewcomment_votes',
    component: () => import('./../views/comment_votes/View.vue'),
    meta: {
      auth: true,
      title: 'comment_votes List'
    }
  },

  {
    path: '/items-list',
    name: 'Listitems',
    component: () => import('./../views/items/List.vue'),
    meta: {
      auth: true,
      title: 'items List'
    }
  },
  {
    path: '/items-list/:id',
    name: 'Viewitems',
    component: () => import('./../views/items/View.vue'),
    meta: {
      auth: true,
      title: 'items List'
    }
  },
  {
    path: '/item_badges-list',
    name: 'Listitem_badges',
    component: () => import('./../views/item_badges/List.vue'),
    meta: {
      auth: true,
      title: 'item_badges List'
    }
  },
  {
    path: '/item_badges-list/:id',
    name: 'Viewitem_badges',
    component: () => import('./../views/item_badges/View.vue'),
    meta: {
      auth: true,
      title: 'item_badges List'
    }
  },

  {
    path: '/item_images-list',
    name: 'Listitem_images',
    component: () => import('./../views/item_images/List.vue'),
    meta: {
      auth: true,
      title: 'item_images List'
    }
  },
  {
    path: '/item_images-list/:id',
    name: 'Viewitem_images',
    component: () => import('./../views/item_images/View.vue'),
    meta: {
      auth: true,
      title: 'item_images List'
    }
  },
  {
    path: '/notifications-list',
    name: 'Listnotifications',
    component: () => import('./../views/notifications/List.vue'),
    meta: {
      auth: true,
      title: 'notifications List'
    }
  },
  {
    path: '/notifications-list/:id',
    name: 'Viewnotifications',
    component: () => import('./../views/notifications/View.vue'),
    meta: {
      auth: true,
      title: 'notifications List'
    }
  },
  {
    path: '/replies-list',
    name: 'Listreplies',
    component: () => import('./../views/replies/List.vue'),
    meta: {
      auth: true,
      title: 'replies List'
    }
  },
  {
    path: '/replies-list/:id',
    name: 'Viewreplies',
    component: () => import('./../views/replies/View.vue'),
    meta: {
      auth: true,
      title: 'replies List'
    }
  },

  {
    path: '/reports-list',
    name: 'Listreports',
    component: () => import('./../views/reports/List.vue'),
    meta: {
      auth: true,
      title: 'reports List'
    }
  },
  {
    path: '/reports-list/:id',
    name: 'Viewreports',
    component: () => import('./../views/reports/View.vue'),
    meta: {
      auth: true,
      title: 'reports List'
    }
  },
  {
    path: '/reviews-list',
    name: 'Listreviews',
    component: () => import('./../views/reviews/List.vue'),
    meta: {
      auth: true,
      title: 'reviews List'
    }
  },
  {
    path: '/reviews-list/:id',
    name: 'Viewreviews',
    component: () => import('./../views/reviews/View.vue'),
    meta: {
      auth: true,
      title: 'reviews List'
    }
  },
  {
    path: '/towns-list',
    name: 'Listtowns',
    component: () => import('./../views/towns/List.vue'),
    meta: {
      auth: true,
      title: 'towns List'
    }
  },
  {
    path: '/towns-list/:id',
    name: 'Viewtowns',
    component: () => import('./../views/towns/View.vue'),
    meta: {
      auth: true,
      title: 'towns List'
    }
  },
  {
    path: '/users-list',
    name: 'Listusers',
    component: () => import('./../views/users/List.vue'),
    meta: {
      auth: true,
      title: 'users List'
    }
  },
  {
    path: '/users-list/:id',
    name: 'Viewusers',
    component: () => import('./../views/users/View.vue'),
    meta: {
      auth: true,
      title: 'users List'
    }
  },
  {
    path: '/user_badges-list',
    name: 'Listuser_badges',
    component: () => import('./../views/user_badges/List.vue'),
    meta: {
      auth: true,
      title: 'user_badges List'
    }
  },
  {
    path: '/user_badges-list/:id',
    name: 'Viewuser_badges',
    component: () => import('./../views/user_badges/View.vue'),
    meta: {
      auth: true,
      title: 'user_badges List'
    }
  },
  {
    path: '/user_reports-list',
    name: 'Listuser_reports',
    component: () => import('./../views/user_reports/List.vue'),
    meta: {
      auth: true,
      title: 'user_reports List'
    }
  },
  {
    path: '/user_reports-list/:id',
    name: 'Viewuser_reports',
    component: () => import('./../views/user_reports/View.vue'),
    meta: {
      auth: true,
      title: 'user_reports List'
    }
  },
]
const router = new VueRouter({
  routes,
  mode: 'history'
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (localStorage.getItem('user')) {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  } else {
    next()
  }
})

export default router