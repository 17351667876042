import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#FF5A5F',
        secondary: '#008000',
      },
      dark: {
        primary: '#FF5A5F',
        secondary: '#008000',
      },
    },
  },
})
