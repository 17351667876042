<template>
    <div>
        <v-app-bar app class="elevation-1" >
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-btn  to="/home" rounded text>
                <v-avatar left size="30">
                    <img :src="logo" alt="alt">
                </v-avatar>
                <v-spacer></v-spacer>
                {{user.admin_name}}
            </v-btn>
            
            <v-spacer></v-spacer>

            <v-btn icon @click="saveTheme">
                <v-icon v-if="$vuetify.theme.dark">fas fa-sun</v-icon>
                <v-icon v-if="!$vuetify.theme.dark">fas fa-moon</v-icon>
            </v-btn>
            <v-btn color="error" icon @click="logout_dialog = true">
                <v-icon>fas fa-sign-out</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app :right="$store.getters.lang.rtl">
            <template v-slot:prepend>
                <v-list-item>
                    <v-list-item-content>
                        <v-img :src="logo" width="50" height="50"></v-img>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-list shaped nav>
                <v-list-item v-for="(item , index) in nav_links" :key="index" :to="item.path">
                    <v-list-item-content>
                        <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-dialog v-model="logout_dialog" scrollable persistent :overlay="false" max-width="500px"
            transition="slide-x-transition">
            <v-card>
                <v-card-title>
                    <span class="headline">Bye 👋</span>
                </v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to logout?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="logout()">Logout</v-btn>
                    <v-btn color="error" @click="logout()">Yes Sure</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                drawer: null,
                logout_dialog: false,
                
                logo: require('../assets/logo.png'),
                links : [

                    {
                        path: '/admins-list',
                        title: 'Admins',
                        icon: 'fas fa-users',
                        role:'admin'
                    },

                    {
                        path: '/categories-list',
                        title: 'Categories',
                        icon: 'fas fa-list',
                        role:'user'
                    },

                    {
                        path: '/cities-list',
                        title: 'Cities',
                        icon: 'fas fa-city',
                        role:'user'
                    },
                    {
                        path: '/towns-list',
                        title: 'Towns',
                        icon: 'fas fa-city',
                        role:'user'
                    },

                    // {
                    //     path: '/comments-list',
                    //     title: 'Comments',
                    // },

                    // {
                    //     path: '/comment_reports-list',
                    //     title: 'Comment Reports',
                    // },

                    // {
                    //     path: '/comment_votes-list',
                    //     title: 'Comment Votes',
                    // },

                    {
                        path: '/items-list',
                        title: 'Items',
                        icon: 'fas fa-shapes',
                        role:'user'
                    },
                        {
                            path: '/badges-list',
                            title: 'Badges',
                            icon: 'fas fa-id-badge',
                            role:'user'
                        },

                    // {
                    //     path: '/item_badges-list',
                    //     title: 'Item Badges',
                    // },

                    // {
                    //     path: '/item_images-list',
                    //     title: 'Item Images',
                    // },

                    // {
                    //     path: '/notifications-list',
                    //     title: 'Notifications',
                    // },

                    // {
                    //     path: '/replies-list',
                    //     title: 'Replies',
                    // },

                    {
                        path: '/reports-list',
                        title: 'Reports',
                        icon: 'fas fa-flag',
                        role:'user'
                    },

                    // {
                    //     path: '/reviews-list',
                    //     title: 'Reviews',
                    // },



                    {
                        path: '/users-list',
                        title: 'Users',
                        icon: 'fas fa-user',
                        role:'admin'
                    },

                    {
                        path: '/user_badges-list',
                        title: 'User Badges',
                        icon: 'fas fa-user-tag',
                        role:'user'
                    },

                    // {
                    //     path: '/user_reports-list',
                    //     title: 'User Reports',
                    // },

                ]
            }
        },
        methods: {
            initData() {
                this.$store.commit('initData')
            },
            logout() {
                this.$store.commit('logout')
                this.$router.push({
                    path: '/login'
                })
            },
            checkTheme() {
                if (localStorage.getItem('theme') && localStorage.getItem('theme') == 'light') {
                    this.$vuetify.theme.dark = false
                } else if(localStorage.getItem('theme') && localStorage.getItem('theme') == 'dark'){
                    this.$vuetify.theme.dark = true
                } else {
                    this.$vuetify.theme.dark = false
                }
            },
            saveTheme() {
                if (this.$vuetify.theme.dark) {
                    localStorage.setItem('theme', 'light')
                    this.$vuetify.theme.dark = false

                } else {
                    this.$vuetify.theme.dark = true
                    localStorage.setItem('theme', 'dark')
                }
            }
        },
        mounted() {
            this.initData()
            this.checkTheme()
        },
        computed: {
            langs() {
                return this.$store.getters.langs
            },
            user() {
                return this.$store.state.auth.user
            },
            nav_links() {
                let links = this.links
                if (this.$store.state.auth.user.admin_role != 'admin') {
                    links = links.filter(f =>{
                        return f.role == 'user'
                    })
                }
                return links
            },
        }
    }
</script>
<style>
    .v-btn {
        text-transform: capitalize !important;
    }
</style>